<template>
  <div class="mb-5">

    <vx-card>
      <vs-row class="mb-5">
        <vs-col vs-w="3">
          <div class="pr-5">
            <label class="vs-input--label">العلامات التجارية</label>
            <v-select name="brand" v-validate="'required'" data-vv-as="العلامات التجارية" label="name"
              :reduce="(item) => item.id" v-model="$store.state.DashboardStatisticsFilter.brand" :options="brands">
            </v-select>
          </div>
        </vs-col>
        <vs-col vs-w="3">
          <div class="pr-5">
            <label class="vs-input--label mt-5">المدة</label>
            <div>
              <flat-pickr @on-change="SetRange" value="" class="form-control" :config="{ mode: 'range' }" />
            </div>
          </div>

        </vs-col>

        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="6">
          <vs-button @click="Filter" style="height:45px" color="primary" type="filled">فلترة</vs-button>
        </vs-col>
      </vs-row>

      <div v-if="Object.keys(dashboardStatistics).length">
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="GridIcon" icon-right
              :statistic="dashboardStatistics.total_products" statisticTitle="عدد المنتجات" color="success" />
          </div>
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="UsersIcon" icon-right
              :statistic="dashboardStatistics.total_users" statisticTitle="عدد المستخدمين" color="warning" />
          </div>
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="ListIcon" icon-right :statistic="
              dashboardStatistics.total_sales -
              dashboardStatistics.total_discount +
              'د.ل '
            " statisticTitle="إجمالي المبيعات" color="success" />
          </div>
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right
              :statistic="dashboardStatistics.profits + 'د.ل '" statisticTitle="إجمالي الأرباح" color="success" />
          </div>

        </div>
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="ShoppingCartIcon" icon-right
              :statistic="dashboardStatistics.total_orders" statisticTitle="إجمالي الطلبات" />
          </div>
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="AlertOctagonIcon" icon-right
              :statistic="dashboardStatistics.orders_under_review" statisticTitle="عدد الطلبات قيد المراجعة"
              color="warning" />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="CheckIcon" icon-right
              :statistic="dashboardStatistics.orders_delivered" statisticTitle="عدد الطلبات التي تم تسليمها"
              color="success" />
          </div>
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="XIcon" icon-right
              :statistic="dashboardStatistics.orders_rejected" statisticTitle="عدد الطلبات المرفوضة" color="danger" />
          </div>
        </div>

      </div>



      <vs-table class="mt-3" :data="$store.state.DashboardStatisticsTableProducts">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <h3 class="my-5 ml-2">
            اكثر المنتجات مبيعاَ
          </h3>
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              {{itemsPerPage}}
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=50">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=100">
                <span>100</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th>
            أسم الصنف
          </vs-th>
          <vs-th>
            الطلبات
          </vs-th>
          <vs-th>
            الطلبات الذي تم توصيلها
          </vs-th>
          <vs-th>
            عدد مرات البيع
          </vs-th>
          <vs-th>
            المبيعات
          </vs-th>

        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].product_name">
              {{ data[indextr].product_name }}
            </vs-td>

            <vs-td :data="data[indextr].orders">
              {{ data[indextr].orders }}
            </vs-td>

            <vs-td :data="data[indextr].orders_delivered">
              {{ data[indextr].orders_delivered }}
            </vs-td>
            <vs-td :data="data[indextr].sold_count">
              {{ data[indextr].sold_count }}
            </vs-td>

            <vs-td :data="data[indextr].sales">
              {{ data[indextr].sales }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-table class="mt-3" :data="$store.state.DashboardStatisticsTable">
        <template slot="header">
          <h3 class="my-5 ml-2">
            ملخص التصنيفات
          </h3>
        </template>
        <template slot="thead">
          <vs-th>
            أسم الصنف
          </vs-th>
          <vs-th>
            الطلبات
          </vs-th>
          <vs-th>
            الطلبات الذي تم توصيلها
          </vs-th>
          <vs-th>
            المبيعات
          </vs-th>
          <vs-th>
            الأرباح
          </vs-th>
          <vs-th>
            التوصيل
          </vs-th>
          <vs-th>
            الدعم
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].category_title">
              {{ data[indextr].category_title }}
            </vs-td>

            <vs-td :data="data[indextr].orders">
              {{ data[indextr].orders }}
            </vs-td>

            <vs-td :data="data[indextr].orders_delivered">
              {{ data[indextr].orders_delivered }}
            </vs-td>

            <vs-td :data="data[indextr].sales">
              {{ data[indextr].sales }}
            </vs-td>

            <vs-td :data="data[indextr].profits">
              {{ data[indextr].profits }}
            </vs-td>

            <vs-td :data="data[indextr].delivery">
              {{ data[indextr].delivery }}
            </vs-td>

            <vs-td :data="data[indextr].support">
              {{ data[indextr].support }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <vs-row>
      <vs-col vs-w="6">
        <div class="p-4 pl-0">
          <vs-card class="mt-5">
            <vue-apex-charts type="line" height="350" :options="lineChart.options" :series="[{
              name: 'الطلبات',
              data: dashboardOrdersByDay || []
            }]">
            </vue-apex-charts>

          </vs-card>
        </div>

      </vs-col>
      <vs-col vs-w="6">
        <div class="p-4 pr-0">
          <vs-card class="mt-5">
            <vue-apex-charts type="bar" height="350" :options="ColumnChart.options" :series="[
              {
                name: 'باقي الطلبات',
                data: dashboardOrdersByCategory.total_orders
              },
              {
                name: 'الطلبات الذي تم توصيلها',
                data: dashboardOrdersByCategory.delivered_orders
              },

            ]">
            </vue-apex-charts>

          </vs-card>
        </div>

      </vs-col>
    </vs-row>
  </div>



</template>

<script>
import moment from "moment";
import moduleProduct from "@/store/products/moduleProduct.js";
import VueApexCharts from 'vue-apexcharts'
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
export default {
  data: () => ({
    itemsPerPage: 20,
    lineChart: {
      options: {
        chart: {
          id: 'vuechart-example'
        },
        stroke: {
          width: 1.5
        },
        xaxis: {
          tickAmount: 5,
          labels: {
            offsetY: 50
          }
        }

      },

    },
    ColumnChart: {

      options: {
        chart: {
          stacked: true,

        },
        colors: ['#E91E63', '#28c76f'],
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
            columnWidth: '55%'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        stacked: true,




        fill: {
          opacity: 1

        },
        tooltip: {
          y: {
            formatter(val) {
              return `$ ${val} thousands`
            }
          }
        }
      }
    },

  }),
  components: { vSelect, flatPickr, StatisticsCardLine, VueApexCharts },
  computed: {
    dashboardStatistics() {
      return this.$store.state.DashboardStatistics;
    },
    dashboardOrdersByDay() {
      return this.$store.state.DashboardOrdersByDay;
    },
    brands() {
      return this.$store.state.product.brands;
    },
    dashboardOrdersByCategory() {
      return this.$store.state.DashboardOrdersByCategory;
    },
  },
  watch: {
    itemsPerPage(newVal) {
      this.$store.dispatch("getDashboardProductsTable", { 'perPage': newVal });
    }
  },
  methods: {
    //Set Duration
    SetRange(range) {
      //only update when the user selects the min & max values
      if (Array.isArray(range) && range.length == 2)
        this.$store.state.DashboardStatisticsFilter.date = range.map((date) =>
          moment(date).format("YYYY-MM-DD")
        );
    },

    Filter() {
      this.$store.dispatch("getDashboardStatistics")
      this.$store.dispatch("getDashboardTable");
      this.$store.dispatch("getDashboardProductsTable");
      this.$store.dispatch("getDashboardOrdersByDay");
      this.$store.dispatch("getDashboardOrdersByCategory");

    }
  },
  created() {
     if (!moduleProduct.isRegistered) {
      this.$store.registerModule("product", moduleProduct);
      moduleProduct.isRegistered = true;
    }
    this.$store.dispatch("product/fetchBrands");
    this.$store.dispatch("getDashboardStatistics");
    this.$store.dispatch("getDashboardTable");
    this.$store.dispatch("getDashboardProductsTable");
    this.$store.dispatch("getDashboardOrdersByDay");
    this.$store.dispatch("getDashboardOrdersByCategory");





  },
};
</script>

<style lang="scss">

</style>
